*{font-family: 'raleway'; }
/********************************* landing page ****************************/
.header-color {
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
} 
.landing-grid {
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.avatar-img {
  height: 250px;
  padding-top: 10em;
  padding-bottom: 5em;
}
.banner-text {
  background-color: grey;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}
.banner-text h1 {
  font-size: 55pt;
  font-weight: bold;
  color: white;
}
.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 5em;
  padding: 9px;
}
/********************************* projects page ****************************/
.projects-grid {
  display: flex;
  flex-wrap: wrap;
}
.card-style {
  height: 325px;
  margin: auto;
  margin-top: 15px;
}
.card-style h2 {
  font-family: 'inconsolata';
  font-size: 40pt;
}
/********************************* contact page ****************************/
.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  padding-top: 2em;
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: auto;
  overflow: auto;
  background: white;
  color: black;
  margin-bottom: 20px;
  border-radius: 25px;
  background: #536976;
  background: -webkit-linear-gradient(to right, #292E49, #536976);
  background:#3f4c6b;

}
.contact-grid h2 {
  font-family: 'Anton', sans-serif;
  color: white;
}
.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
  color: white;
}
.contact-grid hr {
  border-top: 5px dashed white;
  width: 50%;
  margin: auto;
}
.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
  color: white;
}
/********************************* resume page ****************************/
.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}
.resume-img {
  border-radius: 50%;
}
.resume-left-col {
  text-align: center;
}
.resume-left-col hr {
  margin: auto;
  border-top:3px solid #833FB2;
  width:75%;
}
.resume-left-col h5 {
  text-shadow: 1px 0 0;
  font-weight: 300;
}
.resume-right-col p {
  margin: 0;
  padding: 0;
}
.resume-left-col h2 {
  font-family: 'raleway';
}
.resume-right-col h2 {
  font-family: 'raleway';
}
/********************************* skills page ****************************/
.skills-grid h4 {
  margin-top: 0px;
}
.skills-grid {
  text-align: center;
}
.skills-grid hr {
  border-top: 2px solid white;
  width: 75%;
  margin: auto;
}
/********************************* about page ****************************/
.about-grid {
  text-align: center;
  width: 75%;
  height: auto;
  font-family: 'raleway';
  padding-bottom: 10% !important;
}

.about-div {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  padding-top: 2em;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('https://drive.google.com/uc?id=1nlJ4dOBn_DbY2M57y6IKAVtIXT7HkPD3');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about-div img {
  height: 250px;
  border-radius: 15%;
  border: 3px solid white;
}
.about-grid h2 {
  color: white;
  font-family: 'raleway';
}
.about-grid p {
  color: white;
}
